import React, { useRef } from 'react';

import ReactPortal from 'components/Portal';
import Typography from 'components/Typography';
import { useClickOutside } from 'helpers/hooks/useClickOutside';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { formatUserAddress } from 'helpers/string';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';
import { getToFixedNumber } from 'helpers/number';
import TableTag from 'components/Tags/TableTag';

const InfoModal = ({ info, isOpened, setIsOpened }) => {
  const ref = useRef(null);
  const { resetBlurBackground } = useBlurBackground();

  const handleCloseModal = () => {
    resetBlurBackground();
    setIsOpened(false);
  };

  useClickOutside(ref, () => {
    handleCloseModal();
  });

  const statItem = (title, value, isPercents = false) => {
    return (
      <div className="flex flex-col items-start justify-start">
        <Typography
          className={'text-white-500 uppercase'}
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
          text={title}></Typography>
        {isPercents ? (
          <TableTag text={value} />
        ) : title === 'Creator' ? (
          <Typography text={value} variant={TYPOGRAPHY_VARIANTS.BODY_M}></Typography>
        ) : (
          <Typography text={`$${value}`} variant={TYPOGRAPHY_VARIANTS.BODY_M}></Typography>
        )}
      </div>
    );
  };

  return (
    <ReactPortal isOpen={isOpened}>
      <div
        ref={ref}
        className="flex w-[600px] sm:w-full modal text-white fixed top-0 pt-[36px] left-0 h-full z-[999999]">
        <div className="w-full h-full flex items-start justify-center relative py-[50px] overflow-auto  bg-black-1000 rounded-[48px] sm:rounded-tl-[32px] sm:rounded-tr-[32px]">
          <div
            onClick={handleCloseModal}
            className="absolute cursor-pointer top-[30px] right-[30px] bg-white-100 flex items-center justify-center rounded-full w-[44px] h-[44px]">
            <img className="w-[24px] h-[24px]" src="/closeIcon.svg" alt="close icon" />
          </div>

          <div className="flex flex-col mt-[30px] w-full sm:px-6">
            <div className="flex flex-col space-y-6 mr-auto sm:max-w-full w-full">
              <div className="flex items-center space-x-2 w-full">
                <img src={info?.imageUrl} className="w-10 h-10 rounded-full" alt="" />
                <span>{info?.name}</span>
              </div>
              {statItem('price', getToFixedNumber(info?.priceUsd, 4))}
              {statItem('MCAP', getToFixedNumber(info?.marketCapUsd))}
              {statItem('Volume', getToFixedNumber(info?.volumeUsd24h))}
              {statItem('24H', getToFixedNumber(info?.priceUsdChange24h), true)}
              {statItem('Creator', formatUserAddress(info?.creatorAddress || info?.minterAddress))}
            </div>
            <div className="flex flex-col items-start justify-center mt-4 w-full">
              <Typography
                className={'text-white-500 uppercase mb-2'}
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
                text={'Links'}
              />
              <a
                className="mb-3 mt-1 flex items-center justify-center w-full h-[44px] border border-white-200 rounded-full"
                target="_blank"
                rel="nofollow"
                href={`https://tonviewer.com/${info?.minterAddress}`}>
                <img
                  className="h-7 w-7 rounded-[100px] mr-2"
                  src="/icons/dex/tonviewer.jpg"
                  alt="pumpfun link image"
                />
                <Typography variant={TYPOGRAPHY_VARIANTS.BODY_M} text={'Tonviewer'} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default InfoModal;
