import React from 'react';

import CreateToken from 'pages/Ton/CreateToken';
import Market from 'pages/Ton/Market';
import Trade from 'pages/Ton/Trade';

export const TON_ROUTES = [
  { path: '/createToken', component: <CreateToken /> },
  { path: '/market', component: <Market /> },
  { path: '/trade', component: <Trade /> }
];
