import React from 'react';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import Button from 'components/Button';

import { BUTTON_VARIANTS } from 'components/Button/constants';

const MarketTableTon = ({ data, onLoadMore, isShowLoadingButton, isLoading }) => {
  return (
    <React.Fragment>
      <TableHeader />
      {isLoading ? (
        <img
          src="/rocket_gif.gif"
          alt="Looping GIF"
          className="w-[120px] h-[120px] ml-auto mr-auto mt-[100px]"
        />
      ) : (
        data?.result?.map((item, index) => {
          return <TableRow key={index} {...item} />;
        })
      )}
      {isShowLoadingButton && !isLoading && (
        <Button
          className="mt-3"
          onClick={onLoadMore}
          variant={BUTTON_VARIANTS.TABLE_MORE}
          text="More"
        />
      )}
    </React.Fragment>
  );
};

export default MarketTableTon;
