export const SOLANA_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    },
    {
      icon: '/icons/menu.svg',
      title: 'My Tokens',
      link: '/myTokens'
    }
  ],
  pumpfun: [
    {
      icon: '/icons/bundler.svg',
      title: 'Pro Token Launch',
      link: '/bundler'
    }
  ],
  'Raydium Launch': [
    {
      icon: '/icons/circle-plus.svg',
      title: 'Create Token',
      link: '/createToken'
    },
    {
      icon: '/icons/list-apply.svg',
      title: 'Create Liquidity Pool',
      link: '/createLiquidityPool'
    }
  ],
  other: [
    {
      icon: '/icons/referral.svg',
      title: 'Referral System',
      link: '/referral'
    },
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log Out',
      link: '/logout'
    }
  ]
};

export const TON_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    },
    {
      icon: '/icons/market.svg',
      title: 'Create Token',
      link: '/createToken'
    },
    {
      icon: '/icons/trade.svg',
      title: 'Trade',
      link: '/trade'
    }
  ],
  other: [
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log Out',
      link: '/logout'
    }
  ]
};

export const DEFAULT_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    }
  ],
  other: [
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log Out',
      link: '/logout'
    }
  ]
};
